// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";

import masterExample from "@src/views/masters/example/store";
import moduleExample from "@src/views/modules/example/store";
import settingUser from "@src/views/settings/user/store";
import settingRole from "@src/views/settings/role/store";
import settingPermission from "@src/views/settings/permission/store";

const rootReducer = {
  auth,
  navbar,
  layout,
  masterExample,
  moduleExample,
  settingUser,
  settingRole,
  settingPermission,
};

export default rootReducer;
